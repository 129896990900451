import React, { useLayoutEffect } from "react";
import Layout from "../components/layout";
import Campaign from "../components/campaign";
import { useResizeDetector } from "react-resize-detector";
import { useLocation } from "@reach/router";

export default function Campaigns() {
  const { ref: containerRef, height: containerHeight } = useResizeDetector();
  const { ref: lastCampaignRef, height: lastCampaignHeight } =
    useResizeDetector();
  const { hash } = useLocation();
  useLayoutEffect(() => {
    if (hash) {
      const scrollTarget = document.querySelectorAll(hash);
      if (scrollTarget.length) {
        setTimeout(() => scrollTarget[0].scrollIntoView(), 100);
      }
    }
  }, [hash]);

  return (
    <Layout
      pageTitle="Torie Jee | Campaign Samples"
      containerRef={containerRef}
    >
      <h2 className="text-center">Social Campaign Samples</h2>
      <p>
        Much of my portfolio are long-form pieces that are located on hard copy
        media like Blu-rays/DVDs/UHDs (like <cite>Alita: Battle Angel</cite>,{" "}
        <cite>Predator</cite>,{" "}
        <cite>Miss Peregrine's Home for Peculiar Children</cite>,{" "}
        <cite>Assassin's Creed</cite>); here are some of the online social
        campaigns I've worked on:
      </p>
      <Campaign
        anchor="alita-battle-angel"
        heading={<cite>Alita: Battle Angel</cite>}
      >
        <p>
          These are cutdowns I worked with producers on to cut for social
          promotions of our Home Entertainment Release. These were created from
          our longer form behind-the-scenes pieces and cut down to be dynamic,
          easy-to-consume pieces for social media.
        </p>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/watch/?v=2309668115768876"
              rel="external noreferrer"
              target="_blank"
            >
              Rosa Salazar's stunts and training to prepare for{" "}
              <cite>Alita: Battle Angel</cite>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/watch/?v=2467351913355871"
              rel="external noreferrer"
              target="_blank"
            >
              Robert Rodriguez, James Cameron, and Jon Landau's world-building
              and concepts for <cite>Alita: Battle Angel</cite>
            </a>
          </li>
        </ul>
      </Campaign>
      <Campaign anchor="black-ish" heading={<cite>black-ish</cite>}>
        <p>
          The goal with these Artist Influencer social pieces was to celebrate
          art in the black community while recalling iconic moments from the
          past season and promoting the premiere of the new seventh season of{" "}
          <cite>black-ish</cite>! These pieces of art went over so well, that
          stars like Anthony Anderson (1.6m Instagram followers) and Tracee
          Ellis Ross (10.3m Instagram followers) had reposted these to their
          stories/feed, which wasn't part of our paid promotion.
        </p>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/p/CF7ysYhD2OO/"
              rel="external noreferrer"
              target="_blank"
            >
              Mastermindsconnect promotes the special voting episode of{" "}
              <cite>black-ish</cite>
            </a>{" "}
            using a carousel of images to showcase the journey of the Voting
            Rights Act of 1965
          </li>
          <li>
            <a
              href="https://www.instagram.com/p/CGcueAmFaAR/"
              rel="external noreferrer"
              target="_blank"
            >
              Mkoby_ recalls the iconic scene from Season 2 of{" "}
              <cite>black-ish</cite>,
            </a>{" "}
            where the character Diane leaves her siblings' band to pursue work
            as a solo star
          </li>
          <li>
            <a
              href="https://www.instagram.com/p/CGiEmY6lP6k/"
              rel="external noreferrer"
              target="_blank"
            >
              Artist_ria painted this piece celebrating the iconic scene from
              Season 4
            </a>{" "}
            where Pops and Ruby dance in matching track suits. As an added
            bonus,{" "}
            <a
              href="https://www.instagram.com/p/CGipkwoJARn/"
              rel="external noreferrer"
              target="_blank"
            >
              she created a process video of her painting
            </a>{" "}
            with additional promotional messaging.
          </li>
          <li>
            <a
              href="https://www.instagram.com/p/CGnPotKJxox/"
              rel="external noreferrer"
              target="_blank"
            >
              Temi Coker
            </a>
            , who later went on to create the 2021 Academy Awards promotional
            art, created this piece centered on the character Dre
          </li>
        </ul>
      </Campaign>
      <Campaign anchor="rescue-rangers" heading={<cite>Rescue Rangers</cite>}>
        <p>
          Despite Chip N' Dale being pint-sized chipmunks, we knew we had to go
          big with their campaign! I worked with the Tastemade and Disney teams
          to help bring this fun Tiny Kitchen execution to life! The movie had
          mystery elements with cheeky touches, so we of course had to do the
          same for this tasty, cheesy, recipe video that was in-theme with the
          movie! This was posted across multiple social channels like Facebook,
          Instagram, and TikTok to help people get excited about the Rescue
          Rangers' comeback.
        </p>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/tinykitchentm/videos/5286134094772483"
              rel="external noreferrer"
              target="_blank"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/tv/CdtU7DPJ6wL/"
              rel="external noreferrer"
              target="_blank"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@tinykitchentm/video/7099140910004489518"
              rel="external noreferrer"
              target="_blank"
            >
              TikTok
            </a>
          </li>
        </ul>
      </Campaign>
      <Campaign anchor="luca" heading={<cite>Luca</cite>}>
        <p>
          We worked with the So Yummy! team to create these fun recipe videos to
          help promote the Disney+ premiere of Disney and Pixar's{" "}
          <cite>Luca</cite>! Food is such a vibrant and fun part of the film, so
          we wanted to channel that by creating recipes inspired by Luca's home
          of Italy. Different videos were posted to So Yummy!'s Instagram (9.8
          million followers), Pinterest (1.9 million followers), Facebook (31
          million followers), and Snapchat (4.7 million followers)! These are
          some samples below:
        </p>
        <ul>
          <li>
            Facebook post (
            <a
              href="https://fb.watch/6rI1kTlAaf/"
              rel="external noreferrer"
              target="_blank"
            >
              Italian Feast recipes
            </a>
            )
          </li>
          <li>
            Pinterest Posts
            <ul>
              <li>
                <a
                  href="https://www.pinterest.com/pin/584482857894653698/"
                  rel="external noreferrer"
                  target="_blank"
                >
                  Ice Cream Cannoli recipe
                </a>
              </li>
              <li>
                <a
                  href="https://www.pinterest.com/pin/584482857894648150/"
                  rel="external noreferrer"
                  target="_blank"
                >
                  Handmade Pasta and Crispy Crust Pizza recipe
                </a>
              </li>
              <li>
                <a
                  href="https://www.pinterest.com/pin/584482857894602424/"
                  rel="external noreferrer"
                  target="_blank"
                >
                  Pesto recipe
                </a>
              </li>
              <li>
                <a
                  href="https://www.pinterest.com/pin/584482857894619265/"
                  rel="external noreferrer"
                  target="_blank"
                >
                  Raspberry Gelato recipe
                </a>
              </li>
              <li>
                <a
                  href="https://www.pinterest.com/pin/584482857894602516/"
                  rel="external noreferrer"
                  target="_blank"
                >
                  Tiramisu Cake recipe
                </a>
              </li>
            </ul>
          </li>
          <li>
            Instagram Reel (
            <a
              rel="external noreferrer"
              target="_blank"
              href="https://www.instagram.com/reel/CQTopnRhZYM/"
            >
              Kiddo Affogato recipe
            </a>
            )
          </li>
        </ul>
      </Campaign>
      <Campaign anchor="turning-red" heading={<cite>Turning Red</cite>}>
        <p>
          I worked with the Disney and Bustle teams to help oversee this custom
          article execution, highlighting the fun trends of the 2000s that you
          see throughout <cite>Turning Red</cite>! We wanted to emphasize the
          playful nostalgia in the film, and had both a branded IG in-feed post
          promoting the article as well as the article itself.
        </p>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/p/CarvtX9gF9T/"
              rel="external noreferrer"
              target="_blank"
            >
              Instagram In-feed Post
            </a>
          </li>
          <li>
            <a
              href="https://www.bustle.com/entertainment/disney-pixar-turning-red-2000s-nostalgia"
              rel="external noreferrer"
              target="_blank"
            >
              Custom Article
            </a>
          </li>
        </ul>
      </Campaign>
      <Campaign
        anchor="pooch-perfect"
        heading={<cite>Pooch Perfect</cite>}
        ref={lastCampaignRef}
        style={{
          marginBottom:
            containerHeight && lastCampaignHeight
              ? containerHeight > lastCampaignHeight
                ? containerHeight - lastCampaignHeight
                : 0
              : "100%",
        }}
      >
        <p>
          This was a fun, cheeky dog grooming competition show on ABC I worked
          on the digital campaign for. Here are some social promotions for the
          campaign, where my team worked with dog influencers to create cute
          custom pieces for the show! The goal here was to leverage their
          dog-loving fanbase to drive interest and viewers to{" "}
          <cite>Pooch Perfect</cite>.
        </p>
        <p>
          These were posted on Instagram, TikTok, and Twitter to hit their fans
          on all platforms, and was accompanied by an IG story on the day of
          premiere showing the dogs getting in front of the TV to watch the
          show!
        </p>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/p/CMxB9N6J8De/"
              rel="external noreferrer"
              target="_blank"
            >
              Crusoe the Daschund dressing up to emulate his favorite dog look
              on the show
            </a>{" "}
            (824k Instagram Followers, 1.5m TikTok Followers, 34.1k Twitter
            Followers)
          </li>
          <li>
            <a
              href="https://www.instagram.com/p/CMw4GpMhVoa/"
              rel="external noreferrer"
              target="_blank"
            >
              Izzy the Frenchie getting glamorous like the pooches on{" "}
              <cite>Pooch Perfect</cite>
            </a>{" "}
            (1m Instagram followers, 689k TikTok Followers, 4k Twitter
            Followers)
          </li>
          <li>
            <a
              href="https://www.instagram.com/p/CMw_fjqpxfr/"
              rel="external noreferrer"
              target="_blank"
            >
              A Golden Named Kevin creates a stunning look because he's excited
              for the premiere
            </a>{" "}
            (383k Instagram Followers, 1.5m TikTok Followers, 11k Twitter
            Followers)
          </li>
        </ul>
      </Campaign>
    </Layout>
  );
}
