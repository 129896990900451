import React, { forwardRef } from "react";

export default forwardRef(function Campaign(
  { anchor, heading, children, ...props },
  ref
) {
  return (
    <section id={anchor} className="mt-5" ref={ref} {...props}>
      <h3 className="text-center">{heading}</h3>
      {children}
    </section>
  );
});
